// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-index-mdx": () => import("./../../../src/pages/legal/index.mdx" /* webpackChunkName: "component---src-pages-legal-index-mdx" */),
  "component---src-pages-legal-privacy-policy-mdx": () => import("./../../../src/pages/legal/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-legal-privacy-policy-mdx" */),
  "component---src-pages-legal-training-terms-mdx": () => import("./../../../src/pages/legal/training-terms.mdx" /* webpackChunkName: "component---src-pages-legal-training-terms-mdx" */),
  "component---src-pages-legal-website-terms-mdx": () => import("./../../../src/pages/legal/website-terms.mdx" /* webpackChunkName: "component---src-pages-legal-website-terms-mdx" */),
  "component---src-pages-training-effective-typescript-tsx": () => import("./../../../src/pages/training/effective-typescript.tsx" /* webpackChunkName: "component---src-pages-training-effective-typescript-tsx" */),
  "component---src-pages-training-essential-scala-tsx": () => import("./../../../src/pages/training/essential-scala.tsx" /* webpackChunkName: "component---src-pages-training-essential-scala-tsx" */),
  "component---src-pages-training-index-tsx": () => import("./../../../src/pages/training/index.tsx" /* webpackChunkName: "component---src-pages-training-index-tsx" */),
  "component---src-pages-training-react-with-typescript-tsx": () => import("./../../../src/pages/training/react-with-typescript.tsx" /* webpackChunkName: "component---src-pages-training-react-with-typescript-tsx" */),
  "component---src-pages-training-scala-tsx": () => import("./../../../src/pages/training/scala.tsx" /* webpackChunkName: "component---src-pages-training-scala-tsx" */),
  "component---src-pages-training-scala-with-cats-tsx": () => import("./../../../src/pages/training/scala-with-cats.tsx" /* webpackChunkName: "component---src-pages-training-scala-with-cats-tsx" */),
  "component---src-pages-training-typescript-tsx": () => import("./../../../src/pages/training/typescript.tsx" /* webpackChunkName: "component---src-pages-training-typescript-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

